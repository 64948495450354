.fusionovation-labs__faq {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  overflow: hidden;

  @media (min-width: @breakpoint-tablet-large) {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  h2 {
    text-align: center;
  }

  .accordion {
    padding: 0 0.9375rem;
    margin-top: 0.5rem;

    @media (min-width: @breakpoint-tablet) {
      padding: 0 6rem;
    }
    @media (min-width: @breakpoint-tablet-large) {
      padding: 0;
      max-width: @max-width-type;
      margin-right: auto;
      margin-left: auto;
    }

    .card {
      padding: 0;
      box-shadow: none;
      border-bottom: 0.125rem solid @light-grey !important;

      .card-header {
        background-color: transparent;
        padding-right: 0;
        padding-left: 0;
        border: none;

        @media (min-width: @breakpoint-tablet-large) {
          padding: 1.25rem 0;
        }

        & > a {
          &.collapsed {
            h5 {
              i {
                transform: translateY(-50%);
              }
            }
          }
        }

        h5 {
          position: relative;
          color: @black;
          font-family: @font;
          font-size: 1.063rem;
          font-weight: 400;
          padding-right: 1.25rem;

          @media (min-width: @breakpoint-tablet-large) {
            font-size: 1.5rem;
            font-weight: 600;
            padding-right: 3rem;
          }

          i {
            position: absolute;
            right: 0;
            top: 50%;
            font-size: 0.85rem;
            transform: translateY(-50%) rotate(-45deg);
            color: @green;
            transition: all 0.3s ease-in-out;

            @media (min-width: @breakpoint-tablet-large) {
              font-size: 1.5rem;
            }
          }
        }
      }

      .card-body {
        color: @grey;
        font-size: 0.85rem;
        padding: 0 0 1rem;

        @media (min-width: @breakpoint-tablet-large) {
          font-size: 1rem;
          padding: 0 0 1.25rem;
        }
      }
    }
  }
}
