html,
body,
main,
p,
a,
ul li,
ol li,
blockquote,
label {
  font-family: @font;
  font-weight: 400;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: @black;
  font-family: @font-head;
  font-weight: 600;
  line-height: 1.25;
}

h1 {
  font-size: 1.875rem;

  @media (min-width: @breakpoint-tablet-large) {
    font-size: 4.5rem;
  }
}

h2 {
  font-size: 1.25rem;

  @media (min-width: @breakpoint-tablet-large) {
    font-size: 2.25rem;
  }
}

h3 {
  font-size: 1.063rem;

  @media (min-width: @breakpoint-tablet-large) {
    font-size: 1.125rem;
  }
}

p {
  color: @grey;
  font-size: 0.938rem;

  @media (min-width: @breakpoint-tablet-large) {
    font-size: 1rem;
    max-width: @max-width-type;
    margin-right: auto;
    margin-left: auto;
  }

  &.subtitle {
    font-weight: 600;

    @media (min-width: @breakpoint-tablet-large) {
      font-size: 1.25rem;
    }
  }

  a {
    color: @green;

    &:hover {
      color: darken(@green, 15%);
    }
  }
}

// GRID
.fusionovation-labs {
  .container {
    max-width: 100%;
    margin-right: 0;
    margin-left: 0;

    @media (min-height: @breakpoint-tablet) {
      padding-right: @page-margin-tablet;
      padding-left: @page-margin-tablet;
    }
  }
}
