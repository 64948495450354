.fusionovation-labs__header {
  position: relative;
  text-align: center;
  padding-top: 0;
  padding-bottom: 2rem;
  margin-top: 5.375rem;
  overflow: hidden;

  @media (min-width: @breakpoint-tablet-large) {
    margin-top: 8rem;
  }
  @media (min-width: @breakpoint-desktop) {
    max-width: @max-width-body !important;
    margin: 8rem auto 0 !important;
  }

  .fusionovation-labs-header__product {
    position: relative;
    z-index: 2;

    @media (min-width: @breakpoint-tablet-large) {
      align-items: flex-start;
      justify-content: center;
      padding: 0 6rem;
    }

    .fusionovation-labs-header-product__item {
      @media (min-width: @breakpoint-tablet-large) {
        margin-bottom: 2rem;
      }

      &.entrepreneur {
        &:before,
        &:after {
          content: '';

          @media (min-width: @breakpoint-tablet-large) {
            display: block;
            position: absolute;
            background-size: contain;
            z-index: 2;
          }
        }
        &:before {
          @media (min-width: @breakpoint-tablet-large) {
            top: 22%;
            left: 9%;
            background: url('/images/home/line-1.svg') center no-repeat;
            width: 10.5vw;
            height: 10.5vw;
          }
          @media (min-width: @breakpoint-desktop) {
            top: 30%;
            left: 8%;
            width: 10.5vw;
            height: 10.5vw;
          }
          @media (min-width: @max-width-body) {
            width: 11.25rem;
            height: 11.75rem;
          }
        }
        &:after {
          @media (min-width: @breakpoint-tablet-large) {
            top: 0%;
            right: -4vw;
            background: url('/images/home/line-2.svg') center no-repeat;
            width: 8vw;
            height: 1.28vw;
          }
          @media (min-width: @max-width-body) {
            right: -3vw;
            width: 8rem;
            height: 1.3rem;
          }
        }
      }

      &.founders {
        &:before,
        &:after {
          content: '';

          @media (min-width: @breakpoint-tablet-large) {
            display: block;
            position: absolute;
            background-size: contain;
            z-index: 2;
          }
        }
        &:before {
          @media (min-width: @breakpoint-tablet-large) {
            bottom: 50%;
            left: 13%;
            background: url('/images/home/line-3.svg') center no-repeat;
            width: 7.25vw;
            height: 3vw;
          }
          @media (min-width: @breakpoint-desktop) {
            bottom: 33%;
            left: 12%;
            width: 7.25vw;
            height: 3vw;
          }
          @media (min-width: @max-width-body) {
            bottom: 28%;
            width: 7rem;
            height: 4.25rem;
          }
        }
        &:after {
          @media (min-width: @breakpoint-tablet-large) {
            top: 33%;
            right: -3.75vw;
            background: url('/images/home/line-4.svg') center no-repeat;
            width: 8vw;
            height: 2vw;
          }
          @media (min-width: @breakpoint-desktop) {
            top: 43%;
          }
          @media (min-width: @max-width-body) {
            right: -2.5vw;
            width: 8rem;
            height: 2rem;
          }
        }
      }

      &.experts {
        &:before,
        &:after {
          content: '';

          @media (min-width: @breakpoint-tablet-large) {
            display: block;
            position: absolute;
            background-size: contain;
            z-index: 2;
          }
        }
        &:before {
          @media (min-width: @breakpoint-tablet-large) {
            top: 0%;
            left: 23%;
            background: url('/images/home/line-5.svg') center no-repeat;
            width: 4vw;
            height: 3vw;
          }
          @media (min-width: @breakpoint-desktop) {
            top: 2%;
          }
          @media (min-width: @max-width-body) {
            top: 2.25%;
            width: 4.25rem;
            height: 3.25rem;
          }
        }
        &:after {
          @media (min-width: @breakpoint-tablet-large) {
            bottom: 50%;
            right: -3vw;
            background: url('/images/home/line-6.svg') center no-repeat;
            width: 6.5vw;
            height: 2.5vw;
          }
          @media (min-width: @breakpoint-desktop) {
            bottom: 33%;
          }
          @media (min-width: @max-width-body) {
            bottom: 24%;
            right: -2.25vw;
            width: 8rem;
            height: 5rem;
          }
        }
      }

      &.software {
        &:before,
        &:after {
          content: '';

          @media (min-width: @breakpoint-tablet-large) {
            display: block;
            position: absolute;
            background-size: contain;
            z-index: 2;
          }
        }
        &:before {
          @media (min-width: @breakpoint-tablet-large) {
            top: 0%;
            left: 9%;
            background: url('/images/home/line-7.svg') center no-repeat;
            width: 4vw;
            height: 8vw;
          }
          @media (min-width: @max-width-body) {
            top: 1%;
            left: 5%;
            width: 5rem;
            height: 8rem;
          }
        }
        &:after {
          @media (min-width: @breakpoint-tablet-large) {
            bottom: 50%;
            right: 1.5vw;
            background: url('/images/home/line-8.svg') center no-repeat;
            width: 6.25vw;
            height: 6.25vw;
          }
          @media (min-width: @breakpoint-desktop) {
            bottom: 33%;
            right: 1vw;
            width: 6.25vw;
            height: 6.25vw;
          }
          @media (min-width: @max-width-body) {
            bottom: 30%;
            right: 1.25vw;
            width: 6.5rem;
            height: 6.5rem;
          }
        }
      }

      img {
        width: 100%;

        @media (min-width: @breakpoint-tablet) {
          display: block;
          width: 50%;
          margin: 0 auto;
        }
        @media (min-width: @breakpoint-tablet-large) {
          width: 100%;
          margin-bottom: 1.5rem;
        }
      }

      h3 {
        position: relative;
        display: inline-block;
        padding-bottom: 0.375rem;

        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          border-bottom: 0.125rem solid @light-grey;
        }
      }

      p {
        margin-bottom: 0;
      }
    }

    .fusionovation-labs-header-product__plus,
    .fusionovation-labs-header-product__equals {
      display: inline-block;
      color: @black;
      font-family: @font-head;
      font-size: 3.125rem;
      font-weight: 600;
      text-align: center;
      line-height: 1;
      padding: 0;
      margin: 0 auto;

      @media (min-width: @breakpoint-tablet-large) {
        position: absolute;
        top: 17.5%;
        font-size: 3.5rem;
        margin: 0;
      }
      @media (min-width: @breakpoint-desktop) {
        top: 25%;
        font-size: 5rem;
      }
    }

    .fusionovation-labs-header-product__plus {
      &.plus--1 {
        @media (min-width: @breakpoint-tablet-large) {
          left: 28.5%;
        }
        @media (min-width: @breakpoint-desktop) {
          left: 27%;
        }
      }

      &.plus--2 {
        @media (min-width: @breakpoint-tablet-large) {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    .fusionovation-labs-header-product__equals {
      @media (min-width: @breakpoint-tablet-large) {
        left: 68%;
      }
      @media (min-width: @breakpoint-desktop) {
        left: 69.5%;
      }
    }
  }
}
