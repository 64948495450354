header {
  position: fixed;
  top: 0;
  left: 0;
  background-color: @white;
  width: 100%;
  height: 5rem;
  padding: @page-margin;
  transition: all 0s ease-in-out;
  transition-delay: 0.3s;
  overflow-x: hidden;
  z-index: 100;

  @media (min-width: @breakpoint-tablet) {
    padding: @page-margin @page-margin-tablet;
  }
  @media (min-width: @breakpoint-tablet-large) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 7rem;
    overflow: hidden;
  }
  @media (min-width: @max-width-body) {
    padding: @page-margin calc((100% - 84rem) / 2);
  }

  &.is-active {
    height: 100vh;
    transition-delay: 0s;
  }

  .fusionovation-labs-header__brand {
    position: relative;
    display: block;
    width: 6.25rem;
    z-index: 101;

    @media (min-width: @breakpoint-tablet-large) {
      width: 12rem;
    }

    img {
      width: 100%;
    }
  }

  .fusionovation-labs-header__hamburger {
    display: inline-block;
    position: absolute;
    top: @page-margin;
    right: @page-margin;
    transition: all 0.3s ease-in-out;
    z-index: 101;

    @media (min-width: @breakpoint-tablet) {
      right: @page-margin-tablet;
    }
    @media (min-width: @breakpoint-tablet-large) {
      display: none;
    }

    &.is-active {
      transform: rotate(90deg);

      span {
        &:nth-child(1) {
          width: 0.6rem;
        }

        &:nth-child(2) {
          width: 0.9rem;
        }
      }
    }

    span {
      display: block;
      background-color: @black;
      width: 1.1rem;
      height: 0.125rem;
      margin: 0.25rem auto;
      transition: all 0.3s ease-in-out;
    }
  }

  nav.fusionovation-labs-header__navigation {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    background-color: @green;
    width: 0%;
    height: 100vh;
    transition: all 0.3s ease-in-out;
    z-index: 100;

    @media (min-width: @breakpoint-tablet-large) {
      flex-direction: row;
      justify-content: flex-end;
      position: relative;
      top: auto;
      right: auto;
      background-color: transparent;
      width: 50%;
      height: auto;
    }

    &.is-active {
      width: 100%;

      ul {
        // display: block;

        li {
          opacity: 1;
        }
      }
    }

    ul {
      // display: none;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        color: @black;
        font-family: @font;
        font-size: 1.5rem;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 2rem;
        opacity: 0;
        transition: all 0.15s ease-in-out;

        @media (min-width: @breakpoint-tablet-large) {
          display: inline-block;
          font-size: 1rem;
          margin-right: 2rem;
          margin-bottom: 0;
          opacity: 1;
        }

        &:last-child {
          margin-bottom: 0;

          @media (min-width: @breakpoint-tablet-large) {
            margin-right: 0;
          }
        }

        a {
          color: @black;

          &:hover {
            color: @white;

            @media (min-width: @breakpoint-tablet-large) {
              color: @green;
            }
          }
        }
      }
    }
  }
}
