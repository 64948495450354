.fusionovation-labs__offer {
  background-color: @light-grey;
  text-align: center;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;

  @media (min-width: @breakpoint-tablet-large) {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  h2 {}
  p {}
  .fusionovation-labs-offer__list {
    @media (min-width: @breakpoint-tablet-large) {
      align-items: center;
      max-width: @max-width-type;
      padding-top: 2rem;
      padding-bottom: 2rem;
      margin-right: auto;
      margin-left: auto;
    }
    @media (min-width: @breakpoint-desktop) {
      max-width: @max-width-body;
      padding: 2rem 6rem;
    }

    & > img {
      display: none;

      @media (min-width: @breakpoint-tablet-large) {
        display: block;
      }
    }

    ul {
      list-style: none;

      @media (min-width: @breakpoint-tablet-large) {
        margin-bottom: 0;
      }

      li {
        color: @black;
        font-size: 1.063rem;
        margin-bottom: 1.25rem;

        @media (min-width: @breakpoint-tablet) {
          max-width: 50%;
          margin-right: auto;
          margin-left: auto;
        }
        @media (min-width: @breakpoint-tablet-large) {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 1.25rem;
          text-align: left;
          max-width: 100%;
        }

        i {
          display: block;
          color: @green;
          font-size: 1.75rem;

          @media (min-width: @breakpoint-tablet) {
            font-size: 2.25rem;
          }
          @media (min-width: @breakpoint-tablet-large) {
            text-align: center;
            max-width: 3rem;
            min-width: 3rem;
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
