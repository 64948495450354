footer {
  text-align: center;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;

  @media (min-width: @breakpoint-tablet-large) {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  @media (min-width: @breakpoint-desktop) {
    max-width: @max-width-body !important;
    margin: 0 auto !important;
  }

  .row {
    @media (min-width: @breakpoint-tablet-large) {
      align-items: center;
    }
  }

  .fusionovation-labs-footer__social {
    @media (min-width: @breakpoint-desktop) {
      text-align: right;
    }

    p {
      @media (min-width: @breakpoint-tablet-large) {
        margin-bottom: 0;
      }

      a {
        color: @black;
        font-size: 2rem;
        line-height: 1;

        &:hover {
          color: @green;
        }
      }
    }
  }
  .fusionovation-labs-footer__info {
    @media (min-width: @breakpoint-tablet-large) {
      order: -1;
    }
    @media (min-width: @breakpoint-desktop) {
      text-align: left;
    }

    p {
      font-size: 0.813rem;
      line-height: 1.1;
      margin-bottom: 0.75rem;

      @media (min-width: @breakpoint-tablet-large) {
        display: inline-block;
        margin-right: 1rem;
        margin-bottom: 0;
      }
    }
  }
}
