.fusionovation-labs__participants {
  background-color: @green;
  background: rgb(4,186,95); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(4,186,95,1) 0%, rgba(0,140,107,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(4,186,95,1) 0%,rgba(0,140,107,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(4,186,95,1) 0%,rgba(0,140,107,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#04ba5f', endColorstr='#008c6b',GradientType=0 ); /* IE6-9 */
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  overflow: hidden;

  @media (min-width: @breakpoint-tablet-large) {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    margin-top: 2.5rem;
    margin-bottom: 7rem;
  }

  h2 {
    color: @white;
  }

  .fusionovation-labs-participants__slider {
    margin: 1.5rem 0 1rem;

    @media (min-width: @breakpoint-tablet-large) {
      align-items: center;
      margin: 3.5rem 0 1.5rem;
    }
    @media (min-width: @breakpoint-desktop) {
      max-width: @max-width-body;
      margin: 3.5rem auto 1.5rem;
    }

    .fusionovation-labs-participants__participant {
      text-align: center;

      @media (min-width: @breakpoint-tablet-large) {
        display: block;
        float: none;
        margin-right: 0;
      }

      img {
        margin: 0 auto;

        @media (min-width: @breakpoint-tablet) {
          width: 50% !important;
        }
      }
    }

    .carousel-control-prev {
      @media (min-width: @breakpoint-tablet-large) {
        display: none;
      }

      .carousel-control-prev-icon {
        background-image: url('/images/prev-arrow.svg');
      }
    }

    .carousel-control-next {
      @media (min-width: @breakpoint-tablet-large) {
        display: none;
      }

      .carousel-control-next-icon {
        background-image: url('/images/next-arrow.svg');
      }
    }
  }
}
