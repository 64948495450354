html,
body,
main,
p,
a,
ul li,
ol li,
blockquote,
label {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  line-height: 1.5;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #5B5C5E;
  font-family: 'Signika', sans-serif;
  font-weight: 600;
  line-height: 1.25;
}
h1 {
  font-size: 1.875rem;
}
@media (min-width: 900px) {
  h1 {
    font-size: 4.5rem;
  }
}
h2 {
  font-size: 1.25rem;
}
@media (min-width: 900px) {
  h2 {
    font-size: 2.25rem;
  }
}
h3 {
  font-size: 1.063rem;
}
@media (min-width: 900px) {
  h3 {
    font-size: 1.125rem;
  }
}
p {
  color: #989898;
  font-size: 0.938rem;
}
@media (min-width: 900px) {
  p {
    font-size: 1rem;
    max-width: 57rem;
    margin-right: auto;
    margin-left: auto;
  }
}
p.subtitle {
  font-weight: 600;
}
@media (min-width: 900px) {
  p.subtitle {
    font-size: 1.25rem;
  }
}
p a {
  color: #04BA5F;
}
p a:hover {
  color: #026f39;
}
.fusionovation-labs .container {
  max-width: 100%;
  margin-right: 0;
  margin-left: 0;
}
@media (min-height: 600px) {
  .fusionovation-labs .container {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}
header {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #FFFFFF;
  width: 100%;
  height: 5rem;
  padding: 1.75rem;
  transition: all 0s ease-in-out;
  transition-delay: 0.3s;
  overflow-x: hidden;
  z-index: 100;
}
@media (min-width: 600px) {
  header {
    padding: 1.75rem 3rem;
  }
}
@media (min-width: 900px) {
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 7rem;
    overflow: hidden;
  }
}
@media (min-width: 100rem) {
  header {
    padding: 1.75rem calc((100% - 84rem) / 2);
  }
}
header.is-active {
  height: 100vh;
  transition-delay: 0s;
}
header .fusionovation-labs-header__brand {
  position: relative;
  display: block;
  width: 6.25rem;
  z-index: 101;
}
@media (min-width: 900px) {
  header .fusionovation-labs-header__brand {
    width: 12rem;
  }
}
header .fusionovation-labs-header__brand img {
  width: 100%;
}
header .fusionovation-labs-header__hamburger {
  display: inline-block;
  position: absolute;
  top: 1.75rem;
  right: 1.75rem;
  transition: all 0.3s ease-in-out;
  z-index: 101;
}
@media (min-width: 600px) {
  header .fusionovation-labs-header__hamburger {
    right: 3rem;
  }
}
@media (min-width: 900px) {
  header .fusionovation-labs-header__hamburger {
    display: none;
  }
}
header .fusionovation-labs-header__hamburger.is-active {
  transform: rotate(90deg);
}
header .fusionovation-labs-header__hamburger.is-active span:nth-child(1) {
  width: 0.6rem;
}
header .fusionovation-labs-header__hamburger.is-active span:nth-child(2) {
  width: 0.9rem;
}
header .fusionovation-labs-header__hamburger span {
  display: block;
  background-color: #5B5C5E;
  width: 1.1rem;
  height: 0.125rem;
  margin: 0.25rem auto;
  transition: all 0.3s ease-in-out;
}
header nav.fusionovation-labs-header__navigation {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #04BA5F;
  width: 0%;
  height: 100vh;
  transition: all 0.3s ease-in-out;
  z-index: 100;
}
@media (min-width: 900px) {
  header nav.fusionovation-labs-header__navigation {
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    top: auto;
    right: auto;
    background-color: transparent;
    width: 50%;
    height: auto;
  }
}
header nav.fusionovation-labs-header__navigation.is-active {
  width: 100%;
}
header nav.fusionovation-labs-header__navigation.is-active ul li {
  opacity: 1;
}
header nav.fusionovation-labs-header__navigation ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
header nav.fusionovation-labs-header__navigation ul li {
  color: #5B5C5E;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 2rem;
  opacity: 0;
  transition: all 0.15s ease-in-out;
}
@media (min-width: 900px) {
  header nav.fusionovation-labs-header__navigation ul li {
    display: inline-block;
    font-size: 1rem;
    margin-right: 2rem;
    margin-bottom: 0;
    opacity: 1;
  }
}
header nav.fusionovation-labs-header__navigation ul li:last-child {
  margin-bottom: 0;
}
@media (min-width: 900px) {
  header nav.fusionovation-labs-header__navigation ul li:last-child {
    margin-right: 0;
  }
}
header nav.fusionovation-labs-header__navigation ul li a {
  color: #5B5C5E;
}
header nav.fusionovation-labs-header__navigation ul li a:hover {
  color: #FFFFFF;
}
@media (min-width: 900px) {
  header nav.fusionovation-labs-header__navigation ul li a:hover {
    color: #04BA5F;
  }
}
footer {
  text-align: center;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}
@media (min-width: 900px) {
  footer {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
}
@media (min-width: 1200px) {
  footer {
    max-width: 100rem !important;
    margin: 0 auto !important;
  }
}
@media (min-width: 900px) {
  footer .row {
    align-items: center;
  }
}
@media (min-width: 1200px) {
  footer .fusionovation-labs-footer__social {
    text-align: right;
  }
}
@media (min-width: 900px) {
  footer .fusionovation-labs-footer__social p {
    margin-bottom: 0;
  }
}
footer .fusionovation-labs-footer__social p a {
  color: #5B5C5E;
  font-size: 2rem;
  line-height: 1;
}
footer .fusionovation-labs-footer__social p a:hover {
  color: #04BA5F;
}
@media (min-width: 900px) {
  footer .fusionovation-labs-footer__info {
    order: -1;
  }
}
@media (min-width: 1200px) {
  footer .fusionovation-labs-footer__info {
    text-align: left;
  }
}
footer .fusionovation-labs-footer__info p {
  font-size: 0.813rem;
  line-height: 1.1;
  margin-bottom: 0.75rem;
}
@media (min-width: 900px) {
  footer .fusionovation-labs-footer__info p {
    display: inline-block;
    margin-right: 1rem;
    margin-bottom: 0;
  }
}
.fusionovation-labs__header {
  position: relative;
  text-align: center;
  padding-top: 0;
  padding-bottom: 2rem;
  margin-top: 5.375rem;
  overflow: hidden;
}
@media (min-width: 900px) {
  .fusionovation-labs__header {
    margin-top: 8rem;
  }
}
@media (min-width: 1200px) {
  .fusionovation-labs__header {
    max-width: 100rem !important;
    margin: 8rem auto 0 !important;
  }
}
.fusionovation-labs__header .fusionovation-labs-header__product {
  position: relative;
  z-index: 2;
}
@media (min-width: 900px) {
  .fusionovation-labs__header .fusionovation-labs-header__product {
    align-items: flex-start;
    justify-content: center;
    padding: 0 6rem;
  }
}
@media (min-width: 900px) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item {
    margin-bottom: 2rem;
  }
}
.fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.entrepreneur:before,
.fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.entrepreneur:after {
  content: '';
}
@media (min-width: 900px) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.entrepreneur:before,
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.entrepreneur:after {
    display: block;
    position: absolute;
    background-size: contain;
    z-index: 2;
  }
}
@media (min-width: 900px) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.entrepreneur:before {
    top: 22%;
    left: 9%;
    background: url('/images/home/line-1.svg') center no-repeat;
    width: 10.5vw;
    height: 10.5vw;
  }
}
@media (min-width: 1200px) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.entrepreneur:before {
    top: 30%;
    left: 8%;
    width: 10.5vw;
    height: 10.5vw;
  }
}
@media (min-width: 100rem) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.entrepreneur:before {
    width: 11.25rem;
    height: 11.75rem;
  }
}
@media (min-width: 900px) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.entrepreneur:after {
    top: 0%;
    right: -4vw;
    background: url('/images/home/line-2.svg') center no-repeat;
    width: 8vw;
    height: 1.28vw;
  }
}
@media (min-width: 100rem) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.entrepreneur:after {
    right: -3vw;
    width: 8rem;
    height: 1.3rem;
  }
}
.fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.founders:before,
.fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.founders:after {
  content: '';
}
@media (min-width: 900px) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.founders:before,
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.founders:after {
    display: block;
    position: absolute;
    background-size: contain;
    z-index: 2;
  }
}
@media (min-width: 900px) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.founders:before {
    bottom: 50%;
    left: 13%;
    background: url('/images/home/line-3.svg') center no-repeat;
    width: 7.25vw;
    height: 3vw;
  }
}
@media (min-width: 1200px) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.founders:before {
    bottom: 33%;
    left: 12%;
    width: 7.25vw;
    height: 3vw;
  }
}
@media (min-width: 100rem) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.founders:before {
    bottom: 28%;
    width: 7rem;
    height: 4.25rem;
  }
}
@media (min-width: 900px) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.founders:after {
    top: 33%;
    right: -3.75vw;
    background: url('/images/home/line-4.svg') center no-repeat;
    width: 8vw;
    height: 2vw;
  }
}
@media (min-width: 1200px) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.founders:after {
    top: 43%;
  }
}
@media (min-width: 100rem) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.founders:after {
    right: -2.5vw;
    width: 8rem;
    height: 2rem;
  }
}
.fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.experts:before,
.fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.experts:after {
  content: '';
}
@media (min-width: 900px) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.experts:before,
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.experts:after {
    display: block;
    position: absolute;
    background-size: contain;
    z-index: 2;
  }
}
@media (min-width: 900px) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.experts:before {
    top: 0%;
    left: 23%;
    background: url('/images/home/line-5.svg') center no-repeat;
    width: 4vw;
    height: 3vw;
  }
}
@media (min-width: 1200px) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.experts:before {
    top: 2%;
  }
}
@media (min-width: 100rem) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.experts:before {
    top: 2.25%;
    width: 4.25rem;
    height: 3.25rem;
  }
}
@media (min-width: 900px) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.experts:after {
    bottom: 50%;
    right: -3vw;
    background: url('/images/home/line-6.svg') center no-repeat;
    width: 6.5vw;
    height: 2.5vw;
  }
}
@media (min-width: 1200px) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.experts:after {
    bottom: 33%;
  }
}
@media (min-width: 100rem) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.experts:after {
    bottom: 24%;
    right: -2.25vw;
    width: 8rem;
    height: 5rem;
  }
}
.fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.software:before,
.fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.software:after {
  content: '';
}
@media (min-width: 900px) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.software:before,
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.software:after {
    display: block;
    position: absolute;
    background-size: contain;
    z-index: 2;
  }
}
@media (min-width: 900px) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.software:before {
    top: 0%;
    left: 9%;
    background: url('/images/home/line-7.svg') center no-repeat;
    width: 4vw;
    height: 8vw;
  }
}
@media (min-width: 100rem) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.software:before {
    top: 1%;
    left: 5%;
    width: 5rem;
    height: 8rem;
  }
}
@media (min-width: 900px) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.software:after {
    bottom: 50%;
    right: 1.5vw;
    background: url('/images/home/line-8.svg') center no-repeat;
    width: 6.25vw;
    height: 6.25vw;
  }
}
@media (min-width: 1200px) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.software:after {
    bottom: 33%;
    right: 1vw;
    width: 6.25vw;
    height: 6.25vw;
  }
}
@media (min-width: 100rem) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item.software:after {
    bottom: 30%;
    right: 1.25vw;
    width: 6.5rem;
    height: 6.5rem;
  }
}
.fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item img {
  width: 100%;
}
@media (min-width: 600px) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item img {
    display: block;
    width: 50%;
    margin: 0 auto;
  }
}
@media (min-width: 900px) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item img {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}
.fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item h3 {
  position: relative;
  display: inline-block;
  padding-bottom: 0.375rem;
}
.fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item h3::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 0.125rem solid #ECECEC;
}
.fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__item p {
  margin-bottom: 0;
}
.fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__plus,
.fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__equals {
  display: inline-block;
  color: #5B5C5E;
  font-family: 'Signika', sans-serif;
  font-size: 3.125rem;
  font-weight: 600;
  text-align: center;
  line-height: 1;
  padding: 0;
  margin: 0 auto;
}
@media (min-width: 900px) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__plus,
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__equals {
    position: absolute;
    top: 17.5%;
    font-size: 3.5rem;
    margin: 0;
  }
}
@media (min-width: 1200px) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__plus,
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__equals {
    top: 25%;
    font-size: 5rem;
  }
}
@media (min-width: 900px) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__plus.plus--1 {
    left: 28.5%;
  }
}
@media (min-width: 1200px) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__plus.plus--1 {
    left: 27%;
  }
}
@media (min-width: 900px) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__plus.plus--2 {
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (min-width: 900px) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__equals {
    left: 68%;
  }
}
@media (min-width: 1200px) {
  .fusionovation-labs__header .fusionovation-labs-header__product .fusionovation-labs-header-product__equals {
    left: 69.5%;
  }
}
.fusionovation-labs__offer {
  background-color: #ECECEC;
  text-align: center;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}
@media (min-width: 900px) {
  .fusionovation-labs__offer {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
}
@media (min-width: 900px) {
  .fusionovation-labs__offer .fusionovation-labs-offer__list {
    align-items: center;
    max-width: 57rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 1200px) {
  .fusionovation-labs__offer .fusionovation-labs-offer__list {
    max-width: 100rem;
    padding: 2rem 6rem;
  }
}
.fusionovation-labs__offer .fusionovation-labs-offer__list > img {
  display: none;
}
@media (min-width: 900px) {
  .fusionovation-labs__offer .fusionovation-labs-offer__list > img {
    display: block;
  }
}
.fusionovation-labs__offer .fusionovation-labs-offer__list ul {
  list-style: none;
}
@media (min-width: 900px) {
  .fusionovation-labs__offer .fusionovation-labs-offer__list ul {
    margin-bottom: 0;
  }
}
.fusionovation-labs__offer .fusionovation-labs-offer__list ul li {
  color: #5B5C5E;
  font-size: 1.063rem;
  margin-bottom: 1.25rem;
}
@media (min-width: 600px) {
  .fusionovation-labs__offer .fusionovation-labs-offer__list ul li {
    max-width: 50%;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 900px) {
  .fusionovation-labs__offer .fusionovation-labs-offer__list ul li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.25rem;
    text-align: left;
    max-width: 100%;
  }
}
.fusionovation-labs__offer .fusionovation-labs-offer__list ul li i {
  display: block;
  color: #04BA5F;
  font-size: 1.75rem;
}
@media (min-width: 600px) {
  .fusionovation-labs__offer .fusionovation-labs-offer__list ul li i {
    font-size: 2.25rem;
  }
}
@media (min-width: 900px) {
  .fusionovation-labs__offer .fusionovation-labs-offer__list ul li i {
    text-align: center;
    max-width: 3rem;
    min-width: 3rem;
    margin-right: 1rem;
  }
}
.fusionovation-labs__faq {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  overflow: hidden;
}
@media (min-width: 900px) {
  .fusionovation-labs__faq {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
}
.fusionovation-labs__faq h2 {
  text-align: center;
}
.fusionovation-labs__faq .accordion {
  padding: 0 0.9375rem;
  margin-top: 0.5rem;
}
@media (min-width: 600px) {
  .fusionovation-labs__faq .accordion {
    padding: 0 6rem;
  }
}
@media (min-width: 900px) {
  .fusionovation-labs__faq .accordion {
    padding: 0;
    max-width: 57rem;
    margin-right: auto;
    margin-left: auto;
  }
}
.fusionovation-labs__faq .accordion .card {
  padding: 0;
  box-shadow: none;
  border-bottom: 0.125rem solid #ECECEC !important;
}
.fusionovation-labs__faq .accordion .card .card-header {
  background-color: transparent;
  padding-right: 0;
  padding-left: 0;
  border: none;
}
@media (min-width: 900px) {
  .fusionovation-labs__faq .accordion .card .card-header {
    padding: 1.25rem 0;
  }
}
.fusionovation-labs__faq .accordion .card .card-header > a.collapsed h5 i {
  transform: translateY(-50%);
}
.fusionovation-labs__faq .accordion .card .card-header h5 {
  position: relative;
  color: #5B5C5E;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.063rem;
  font-weight: 400;
  padding-right: 1.25rem;
}
@media (min-width: 900px) {
  .fusionovation-labs__faq .accordion .card .card-header h5 {
    font-size: 1.5rem;
    font-weight: 600;
    padding-right: 3rem;
  }
}
.fusionovation-labs__faq .accordion .card .card-header h5 i {
  position: absolute;
  right: 0;
  top: 50%;
  font-size: 0.85rem;
  transform: translateY(-50%) rotate(-45deg);
  color: #04BA5F;
  transition: all 0.3s ease-in-out;
}
@media (min-width: 900px) {
  .fusionovation-labs__faq .accordion .card .card-header h5 i {
    font-size: 1.5rem;
  }
}
.fusionovation-labs__faq .accordion .card .card-body {
  color: #989898;
  font-size: 0.85rem;
  padding: 0 0 1rem;
}
@media (min-width: 900px) {
  .fusionovation-labs__faq .accordion .card .card-body {
    font-size: 1rem;
    padding: 0 0 1.25rem;
  }
}
.fusionovation-labs__participants {
  background-color: #04BA5F;
  background: #04ba5f;
  /* Old browsers */
  background: -moz-linear-gradient(top, #04ba5f 0%, #008c6b 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #04ba5f 0%, #008c6b 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #04ba5f 0%, #008c6b 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#04ba5f', endColorstr='#008c6b', GradientType=0);
  /* IE6-9 */
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  overflow: hidden;
}
@media (min-width: 900px) {
  .fusionovation-labs__participants {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    margin-top: 2.5rem;
    margin-bottom: 7rem;
  }
}
.fusionovation-labs__participants h2 {
  color: #FFFFFF;
}
.fusionovation-labs__participants .fusionovation-labs-participants__slider {
  margin: 1.5rem 0 1rem;
}
@media (min-width: 900px) {
  .fusionovation-labs__participants .fusionovation-labs-participants__slider {
    align-items: center;
    margin: 3.5rem 0 1.5rem;
  }
}
@media (min-width: 1200px) {
  .fusionovation-labs__participants .fusionovation-labs-participants__slider {
    max-width: 100rem;
    margin: 3.5rem auto 1.5rem;
  }
}
.fusionovation-labs__participants .fusionovation-labs-participants__slider .fusionovation-labs-participants__participant {
  text-align: center;
}
@media (min-width: 900px) {
  .fusionovation-labs__participants .fusionovation-labs-participants__slider .fusionovation-labs-participants__participant {
    display: block;
    float: none;
    margin-right: 0;
  }
}
.fusionovation-labs__participants .fusionovation-labs-participants__slider .fusionovation-labs-participants__participant img {
  margin: 0 auto;
}
@media (min-width: 600px) {
  .fusionovation-labs__participants .fusionovation-labs-participants__slider .fusionovation-labs-participants__participant img {
    width: 50% !important;
  }
}
@media (min-width: 900px) {
  .fusionovation-labs__participants .fusionovation-labs-participants__slider .carousel-control-prev {
    display: none;
  }
}
.fusionovation-labs__participants .fusionovation-labs-participants__slider .carousel-control-prev .carousel-control-prev-icon {
  background-image: url('/images/prev-arrow.svg');
}
@media (min-width: 900px) {
  .fusionovation-labs__participants .fusionovation-labs-participants__slider .carousel-control-next {
    display: none;
  }
}
.fusionovation-labs__participants .fusionovation-labs-participants__slider .carousel-control-next .carousel-control-next-icon {
  background-image: url('/images/next-arrow.svg');
}
.fusionovation-labs__contact {
  text-align: center;
  padding-top: 1.875rem;
  overflow-x: hidden;
}
@media (min-height: 600px) {
  .fusionovation-labs__contact {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}
@media (min-width: 900px) {
  .fusionovation-labs__contact {
    background-color: #ECECEC;
    text-align: left;
    padding: 3.5rem 3rem !important;
  }
}
@media (min-width: 1200px) {
  .fusionovation-labs__contact {
    padding: 3.5rem 0 !important;
  }
}
@media (min-width: 1200px) {
  .fusionovation-labs__contact .row {
    max-width: 100rem;
    padding: 0 6.5rem;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-height: 600px) {
  .fusionovation-labs__contact .fusionovation-labs-contact__info {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}
@media (min-width: 1200px) {
  .fusionovation-labs__contact .fusionovation-labs-contact__info {
    padding: 0;
  }
}
.fusionovation-labs__contact .fusionovation-labs-contact__info img {
  display: none;
}
@media (min-width: 900px) {
  .fusionovation-labs__contact .fusionovation-labs-contact__info img {
    display: block;
    width: 100%;
    margin-top: 3rem;
  }
}
.fusionovation-labs__contact .fusionovation-labs-contact__form {
  background-color: #ECECEC;
  max-width: 100%;
  padding: 1.875rem 1.75rem;
}
@media (min-width: 600px) {
  .fusionovation-labs__contact .fusionovation-labs-contact__form {
    padding: 1.875rem 6rem;
  }
}
@media (min-width: 900px) {
  .fusionovation-labs__contact .fusionovation-labs-contact__form {
    background-color: transparent;
    max-width: 66.666667%;
    padding: 0 1.75rem;
  }
}
@media (min-width: 1200px) {
  .fusionovation-labs__contact .fusionovation-labs-contact__form {
    padding: 0 0 0 3rem;
  }
}
