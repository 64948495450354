.fusionovation-labs__contact {
  text-align: center;
  padding-top: 1.875rem;
  overflow-x: hidden;

  @media (min-height: @breakpoint-tablet) {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  @media (min-width: @breakpoint-tablet-large) {
    background-color: @light-grey;
    text-align: left;
    padding: 3.5rem 3rem !important;
  }
  @media (min-width: @breakpoint-desktop) {
    padding: 3.5rem 0 !important;
  }

  .row {
    @media (min-width: @breakpoint-desktop) {
      max-width: @max-width-body;
      padding: 0 6.5rem;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .fusionovation-labs-contact__info {
    @media (min-height: @breakpoint-tablet) {
      padding-right: @page-margin-tablet;
      padding-left: @page-margin-tablet;
    }
    @media (min-width: @breakpoint-desktop) {
      padding: 0;
    }

    h2 {}
    p {}
    img {
      display: none;

      @media (min-width: @breakpoint-tablet-large) {
        display: block;
        width: 100%;
        margin-top: 3rem;
      }
    }
  }

  .fusionovation-labs-contact__form {
    background-color: @light-grey;
    max-width: 100%;
    padding: 1.875rem @page-margin;

    @media (min-width: @breakpoint-tablet) {
      padding: 1.875rem 6rem;
    }
    @media (min-width: @breakpoint-tablet-large) {
      background-color: transparent;
      max-width: 66.666667%;
      padding: 0 @page-margin;
    }
    @media (min-width: @breakpoint-desktop) {
      padding: 0 0 0 3rem;
    }
  }
}
